
import { CharismaApi } from "../CharismaApi.js";

export const CharismaTrainingApi = {
 
    ListTrainings: async function(pTrash : number){

        const lResponse = await CharismaApi.request({
            url:`/trainings/listTrainings.php`,
            method:"GET",
            params:{
                trash: pTrash
            }
        })
        return lResponse.data;
    },
    GetTraining: async function(pTrainingId : number){

        const lResponse = await CharismaApi.request({
            url:`/trainings/getTraining.php`,
            method:"GET",
            params:{
                trainingId : pTrainingId
            }
        })
        return lResponse.data;
    },
    CreateTraining: async function(pName : string, pType : number, pStartDate : string, pEndDate : string, pResponsibleId : number){
        const lDatas = new FormData();
        lDatas.append('name', pName);
        lDatas.append('type', pType);
        lDatas.append('startDate', pStartDate);
        lDatas.append('endDate', pEndDate);
        lDatas.append('responsibleId', pResponsibleId);
        const lResponse = await CharismaApi.request({
            url:`/trainings/createTraining.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    UpdateTraining: async function(pName : string, pType : number, pStartDate : string, pEndDate : string, 
                pResponsibleId:number, pTrainingId : number, pCalendarId : string){
        const lDatas = new FormData();
        lDatas.append('name', pName);
        lDatas.append('startDate', pStartDate);
        lDatas.append('endDate', pEndDate);
        lDatas.append('type', pType);
        lDatas.append('responsibleId', pResponsibleId);
        lDatas.append('trainingId', pTrainingId);
        lDatas.append('calendarId', pCalendarId);
        const lResponse = await CharismaApi.request({
            url:`/trainings/updateTraining.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },    
    DeleteTrainings: async function(pIds : number[]){
        const lDatas = new FormData();
        pIds.forEach((lId)=>{
            lDatas.append("trainingIds[]", lId);    
        });
        const lResponse = await CharismaApi.request({
            url:`/trainings/deleteTrainings.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    TrashTrainings: async function(pIds : number[]){
        const lDatas = new FormData();
        pIds.forEach((lId)=>{
            lDatas.append("trainingIds[]", lId) 
        });
        const lResponse = await CharismaApi.request({
            url:`/trainings/trashTrainings.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },  
    RestoreTrainings: async function(pIds : number[]){
        const lDatas = new FormData();
        pIds.forEach((lId)=>{
            lDatas.append("trainingIds[]", lId)
        });
        const lResponse = await CharismaApi.request({
            url:`/trainings/restorePromotions.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },         
    ListCourses: async function(pTrainingId : number, pTrash : number){

        const lResponse = await CharismaApi.request({
            url:`/trainings/listCourses.php`,
            method:"GET",
            params:{
                trainingId : pTrainingId,
                trash: pTrash
            }
        })
        return lResponse.data;
    },
    GetCourse: async function(pCourseId : number){

        const lResponse = await CharismaApi.request({
            url:`/trainings/getCourse.php`,
            method:"GET",
            params:{
                courseId : pCourseId
            }
        })
        return lResponse.data;
    },
    CreateCourse: async function(pName : string, pStartDate : string, pEndDate : string, pTrainingId : number){
        const lDatas = new FormData();
        lDatas.append('name', pName);
        lDatas.append('startDate', pStartDate);
        lDatas.append('endDate', pEndDate);
        lDatas.append('trainingId', pTrainingId);
        const lResponse = await CharismaApi.request({
            url:`/trainings/createCourse.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    UpdateCourse: async function(pName : string, pStartDate : string, pEndDate : string, pCourseId : number){
        const lDatas = new FormData();
        lDatas.append('name', pName);
        lDatas.append('startDate', pStartDate);
        lDatas.append('endDate', pEndDate);
        lDatas.append('courseId', pCourseId);
        const lResponse = await CharismaApi.request({
            url:`/trainings/updateCourse.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },    
    DeleteCourses: async function(pIds : number[]){
        const lDatas = new FormData();
        pIds.forEach((lId)=>{
            lDatas.append("courseIds[]", lId);    
        });
        const lResponse = await CharismaApi.request({
            url:`/trainings/deleteCourses.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    TrashCourses: async function(pIds : number[]){
        const lDatas = new FormData();
        pIds.forEach((lId)=>{
            lDatas.append("courseIds[]", lId)
        });
        const lResponse = await CharismaApi.request({
            url:`/trainings/trashCourses.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },  
    RestoreCourses: async function(pIds : number[]){
        const lDatas = new FormData();
        pIds.forEach((lId)=>{
            lDatas.append("courseIds[]", lId)
        });
        const lResponse = await CharismaApi.request({
            url:`/trainings/restoreCourses.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },    
    ListStudentDocument: async function(pTrainingId:number){
        const lResponse = await CharismaApi.request({
            url:`/trainings/listStudentDocuments.php`,
            method:"GET",
            params:{
                trainingId:pTrainingId
            }
        })
        return lResponse.data;
    },
    CreateStudentDocument: async function(pTrainingId:number, pType:string){
        const lDatas = new FormData();
        lDatas.append('trainingId', pTrainingId);
        lDatas.append('type', pType);
        const lResponse = await CharismaApi.request({
            url:`/trainings/createStudentDocument.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    UpdateStudentDocument: async function(pType:string, pId:number){
        const lDatas = new FormData();
        lDatas.append('id', pId);
        lDatas.append('type', pType);
        const lResponse = await CharismaApi.request({
            url:`/trainings/updateStudentDocument.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    DeleteStudentDocument: async function(pIds : number[]){
        const lDatas = new FormData();
        pIds.forEach((lId)=>{
            lDatas.append("ids[]", lId);    
        });
        const lResponse = await CharismaApi.request({
            url:`/trainings/deleteStudentDocuments.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },     
    AddTrainingDocument: async function(pTrainingId:number, pDocuments:number[], pType:string){
        const lDatas = new FormData();
        pDocuments.forEach((lId)=>{
            lDatas.append("documentIds[]", lId)
        });
        lDatas.append("trainingId", pTrainingId)
        lDatas.append("type", pType);
        const lResponse = await CharismaApi.request({
            url:`/trainings/addTrainingDocument.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    ListTrainingDocument: async function(pTrainingId:number, pType:string){
        const lResponse = await CharismaApi.request({
            url:`/trainings/listTrainingDocuments.php`,
            method:"GET",
            params:{
                trainingId:pTrainingId,
                type: pType
            }
        })
        return lResponse.data;
    },
    DeleteTrainingDocument: async function(pDocuments:number[]){
        const lDatas = new FormData();
        pDocuments.forEach((lId)=>{
            lDatas.append("documentIds[]", lId)
        });
        const lResponse = await CharismaApi.request({
            url:`/trainings/deleteTrainingDocuments.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },    
    UpdateTrainingDocumentToAccept: async function(pId:number, pAccept:number){
        const lDatas = new FormData();
        lDatas.append("documentId", pId);
        lDatas.append("accept", pAccept);
        const lResponse = await CharismaApi.request({
            url:`/trainings/updateTrainingDocumentToAccept.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },   
    AddPicture: async function(pId : number, pPicture : File){
        const lDatas = new FormData();
        lDatas.append('id', pId);
        lDatas.append('picture', pPicture);
        const lResponse = await CharismaApi.request({
            url:`/trainings/addPicture.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    ListPictures: async function(pId : number){
   
        const lResponse = await CharismaApi.request({
            url:`/trainings/listPictures.php`,
            method:"GET",
            params:{
                "trainingId" : pId
            }
        })
        return lResponse.data;
    },
    DeletePictures: async function(pPictures : string[], pId : number){
        const lDatas = new FormData();
        lDatas.append('id', pId);        
        pPictures.forEach((lPicture)=>{
            lDatas.append("pictures[]", lPicture);    
        });
        const lResponse = await CharismaApi.request({
            url:`/trainings/deletePictures.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    AddAssessmentTemplate: async function(pTemplateId : number, pTrainingId : number, pType : number){
        const lDatas = new FormData();
        lDatas.append('trainingId', pTrainingId);
        lDatas.append('templateId', pTemplateId);
        lDatas.append('type', pType);
        const lResponse = await CharismaApi.request({
            url:`/trainings/addAssessmentTemplate.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    GetAssessmentTemplate: async function(pTrainingId : number, pType : number){
   
        const lResponse = await CharismaApi.request({
            url:`/trainings/getAssessmentTemplate.php`,
            method:"GET",
            params:{
                "trainingId" : pTrainingId,
                "type" : pType
            }
        })
        return lResponse.data;
    },
    DeleteAssessmentTemplate: async function(pId : number){
        const lDatas = new FormData();
        lDatas.append('id', pId);        
        
        const lResponse = await CharismaApi.request({
            url:`/trainings/deleteAssessmentTemplate.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },      
    ListTrainingPeriods: async function(pTrainingId : number){

        const lResponse = await CharismaApi.request({
            url:`/trainings/listTrainingPeriods.php`,
            method:"GET",
            params:{
                trainingId : pTrainingId
            }
        })
        return lResponse.data;
    },
    GetTrainingPeriod: async function(pTrainingPeriodId : number){

        const lResponse = await CharismaApi.request({
            url:`/trainings/getTrainingPeriod.php`,
            method:"GET",
            params:{
                trainingPeriodId : pTrainingPeriodId
            }
        })
        return lResponse.data;
    },
    CreateTrainingPeriod: async function(pName : string, pStartDate : string, pEndDate : string, pTrainingId : number){
        const lDatas = new FormData();
        lDatas.append('name', pName);
        lDatas.append('startDate', pStartDate);
        lDatas.append('endDate', pEndDate);
        lDatas.append('trainingId', pTrainingId);
        const lResponse = await CharismaApi.request({
            url:`/trainings/createTrainingPeriod.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    UpdateTrainingPeriod: async function(pName : string, pStartDate : string, pEndDate : string, pTrainingPeriodId : number){
        const lDatas = new FormData();
        lDatas.append('name', pName);
        lDatas.append('startDate', pStartDate);
        lDatas.append('endDate', pEndDate);
        lDatas.append('trainingPeriodId', pTrainingPeriodId);
        const lResponse = await CharismaApi.request({
            url:`/trainings/updateTrainingPeriod.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },    
    DeleteTrainingPeriods: async function(pIds : number[]){
        const lDatas = new FormData();
        pIds.forEach((lId)=>{
            lDatas.append("trainingPeriodIds[]", lId);    
        });
        const lResponse = await CharismaApi.request({
            url:`/trainings/deleteTrainingPeriods.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },    
    GenerateAssessment: async function(pDate : string, pTraininId : number, pCourses: number[], pAssessmentType : number){
        const lDatas = new FormData();
        lDatas.append('date', pDate);
        lDatas.append('trainingId', pTraininId);
        pCourses.forEach((lId)=>{
            lDatas.append("courses[]", lId);    
        });
        lDatas.append('type', pAssessmentType);
        const lResponse = await CharismaApi.request({
            url:`/studentTrainings/generateAssessment.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },     
    GenerateEvaluation: async function(pTemplateId : number,  pType : number, pDate : string, 
                                    pTrainingId : number, pProfile : number, pCourses : number[]){
        const lDatas = new FormData();
        lDatas.append('type', pType);
        lDatas.append('date', pDate);
        lDatas.append('trainingId', pTrainingId);
        lDatas.append('templateId', pTemplateId);
        lDatas.append('profile', pProfile);
        pCourses.forEach((lId)=>{
            lDatas.append("courses[]", lId);    
        });        
        const lResponse = await CharismaApi.request({
            url:`/studentTrainings/generateEvaluation.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },      
}