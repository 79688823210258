import * as React from 'react';


import Rating from '@mui/material/Rating'
import StarIcon from '@mui/icons-material/Star';
import ThunderstormIcon from '@mui/icons-material/Thunderstorm';



export default function CharismaRating(props){
        
       
    const [hoverIndex, setHoverIndex] = React.useState(-1);
        
    return(
        
        <Rating
            value={props.value}
            onChange={(event, value)=>props.onChange(props.id, value)}
            max={props.max}
            disabled = {props.readOnly}
            onChangeActive={(_, v) => setHoverIndex(v)}
            IconContainerComponent={({ value: v, ...restProps }) => {
                const isActive = hoverIndex >= v || (hoverIndex === -1 && props.value >= v);
                const color = isActive ? "orange" : "gray"
                return (
                    <span>
                        {v == 1 ? <ThunderstormIcon sx={{color:color}}/> : <StarIcon sx={{color:color}}/>}
                    </span>
                );
            }}
        />      
    );
}