import * as React from 'react';

import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader';
import CharismaDocumentsForm from '../documents/documentsForm.tsx';
import {CharismaTrainingApi} from '../api/trainings/CharismaTrainingApi.tsx';
import Checkbox from '@mui/material/Checkbox'
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase'
import { Link } from 'react-router-dom';
import {formatDate} from '../utils.tsx'
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography';


const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));


export default function CharismaTrainingDocs(props){
    
    const lType = props.type;
    const lTrainingId = props.trainingId;
    
    const [add, setAdd] = React.useState(false);
    const [documents, setDocuments] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [search, setSearch] = React.useState('');
    const [deleteDocuments, setDeleteDocuments] = React.useState('');
    
    //const lCurrentUser = React.useContext(CharismaUserContext);
    const lReadWrite = !props.readOnly;
    
    
    function onCancel(){
        setAdd(false);
    }
    
        
    React.useEffect(()=>{
        const lResponse = CharismaTrainingApi.ListTrainingDocument(lTrainingId, lType);
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.documents !== null){
                    setDocuments(value.documents);
                }
                
            }
        }).catch((error)=>{
            
        })
        setSelected([]);
    }, [lTrainingId, lType])
    
    
    function handleSelected(pEvent : React.ChangeEvent<HTMLInputElement>, pId : number){
        let lNewSelected : number[] = [];
        let lIndex = selected.indexOf(pId);
        if(pEvent.target.checked && lIndex === -1){
            lNewSelected = lNewSelected.concat(selected, pId);
        }
        else if(lIndex !== -1){
            if(lIndex === 0){
                lNewSelected = lNewSelected.concat(selected.slice(1));
            }
            else if(lIndex === selected.length - 1){
                lNewSelected = lNewSelected.concat(selected.slice(0, -1));
            }
            else{
                lNewSelected = lNewSelected.concat(selected.slice(0, lIndex), selected.slice(lIndex + 1),);
            }
        }
        setSelected(lNewSelected);
    }
    
    function handleValid(pEvent : React.ChangeEvent<HTMLInputElement>, pId : number){
        const lToAccept = pEvent.target.checked ? 1 : 0;
        const lResponse = CharismaTrainingApi.UpdateTrainingDocumentToAccept(pId,lToAccept);
        lResponse.then((value)=>{
            if(value.error === 0){
                const lId = value["documentId"];
                const lAccept = value["accept"];     
                let lDocuments = documents.slice();
                for(let lIndex = 0; lIndex < lDocuments.length; lIndex++){
                    if(lDocuments[lIndex].id === lId){
                        lDocuments[lIndex].toAccept = lAccept;                        
                        break;
                    }
                }
                setDocuments(lDocuments);        
            }
        }).catch((error)=>{
            
        })        
    }    
    
    function handleDeleteDocuments(){
        const lResponse = CharismaTrainingApi.DeleteTrainingDocument(selected);
        lResponse.then((value)=>{
            if(value.error === 0){
                const lIds = value["ids"];
                console.log(lIds);
                let lDocuments = documents.filter((doc)=>lIds.indexOf(doc.id) === -1 );
                setDocuments(lDocuments);        
                setDeleteDocuments(false);
            }
        }).catch((error)=>{
            
        })                
    }
    
    function onValid(pDocs : number[]){
        const lResponse = CharismaTrainingApi.AddTrainingDocument(lTrainingId, pDocs, lType);
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.documents !== null){
                    setDocuments(value.documents);
                }
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })
        setAdd(false);
    }
    
    const lDocuments = documents.filter((document)=>(document.name.toLowerCase().includes(search.toLowerCase())));
    
    return(
        <Box>
            <Card>
                <CardHeader
                    title={props.title}
                    action={
                        <div>
                        {
                        lReadWrite ?
                        <IconButton onClick={()=>setAdd(true)}>
                            <AddIcon/>
                        </IconButton>
                        :
                        null
                        }
                        {selected.length > 0 ?
                            <IconButton onClick={()=>setDeleteDocuments(true)}>
                                <DeleteIcon/>
                            </IconButton>  
                            :
                            null
                        }
                        </div>                          
                    }
                />
                <CardContent>
                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Rechercher…"
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={(event)=>setSearch(event.target.value)}
                            value={search}
                        />
                    </Search>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {lReadWrite ?
                                    <TableCell padding="checkbox">
                                    </TableCell>
                                    :
                                    null
                                }
                                <TableCell>Nom</TableCell>
                                {lReadWrite ?
                                    <TableCell sx={{display:{xs:'none', md:'table-cell'}}}>Ajouté par</TableCell>
                                    :
                                    null
                                }
                                {lReadWrite ?
                                    <TableCell sx={{display:{xs:'none', md:'table-cell'}}}>Mis à jour le</TableCell>
                                    :
                                    null
                                }
                                {lReadWrite && props.accept?
                                    <TableCell align='center'>
                                        A accepter
                                    </TableCell>
                                    :
                                    null
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>                      
                        {
                            lDocuments.map((document)=>(
                                <TableRow key={document.name}>
                                    {lReadWrite ?
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={selected.indexOf(document.id) >= 0}
                                                onClick={(event)=>handleSelected(event, document.id)}
                                            />
                                        </TableCell>
                                        :
                                        null
                                    }
                                    <TableCell>
                                        <Link to={document.link} target="_blank" rel="noopener noreferrer" >
                                            {document.name}
                                        </Link>
                                    </TableCell>
                                    {lReadWrite ?
                                        <TableCell sx={{display:{xs:'none', md:'table-cell'}}}>
                                            {document.owner}
                                        </TableCell>
                                        :
                                        null
                                    }
                                    {lReadWrite ?
                                        <TableCell sx={{display:{xs:'none', md:'table-cell'}}}>
                                            {formatDate(document.lastModified)}
                                        </TableCell>
                                        :
                                        null
                                    }
                                    {lReadWrite && props.accept ?
                                        <TableCell align='center'>
                                            <Checkbox
                                                checked={document.toAccept}
                                                onClick={(event)=>handleValid(event, document.id)}
                                            />
                                        </TableCell>
                                        :
                                        null
                                    }
                                </TableRow>
                            ))
                        }                      
                        
                        </TableBody>
                    </Table>                
                </CardContent>
            </Card>  
            {
                add?
                <CharismaDocumentsForm
                    onCancel={onCancel}
                    onValid={onValid}
                />
                :
                null
            }
            <Dialog
                open={deleteDocuments}
            >
                <DialogTitle>Suppression de Documents</DialogTitle>
                <DialogContent>
                    <Typography>
                        Etes-vous sûr de vouloir supprimer de la fromation les documents sélectionnées?
                    </Typography>                                             
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setDeleteDocuments(false)}>
                        Annuler
                    </Button>
                    <Button onClick={handleDeleteDocuments}>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog> 
        </Box>      
    );
}