import * as React from 'react';


import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader';
import { CharismaGoogleApi } from '../api/google/CharismaGoogleApi.tsx'

import Grid from '@mui/material/Grid'

import Typography from '@mui/material/Typography';




export default function CharismaSettings(props){
    


         
    function handleInitGoogle(){
        const lResponse = CharismaGoogleApi.Initialize();
        lResponse.then((value)=>{
            if(value["url"]){
                window.open(value["url"], "_blank");
            }
        }).catch((error)=>{
            
        })   
    }
    
    return(
        <Box>
            <Card>
                <CardHeader
                    title={
                        <Box>
                            <Typography>{"Connexion Google"}</Typography>  
                        </Box>
                    } 
                />                
                <CardContent>
                    <Button
                        onClick={()=>handleInitGoogle()}
                        variant='primary'
                    >
                    Initialisation
                    </Button>
                </CardContent>
            </Card>     
  
        </Box>      
    );
    
}