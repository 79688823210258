import * as React from 'react';

import Alert from "@mui/material/Alert"
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import {readSheetNames} from 'read-excel-file'
import readXlsxFile from 'read-excel-file'

import { ValidatePicture, ValidateFormat } from '../utils/Validator.tsx';

export default function CharismaSchedulerFileForm(props) {

    const [file, setFile] = React.useState(null);
    const [error, setError] = React.useState(0);
    const [sheets, setSheets] = React.useState([]);
    const [trainings, setTrainings] = React.useState([]);
    const [selectedTrainings, setSelectedTrainings] = React.useState([]);
    const [cError, setCError] = React.useState(0);
    const [sheet, setSheet] = React.useState(null);
    const [wait, setWait] = React.useState(false);

    React.useEffect(() => {
        if(file !== null && sheet !== null){
            readXlsxFile(file, {sheet: sheet}).then((datas) => {
                let lTrainings = [];
                for(let index = 1; index < datas.length; index++){
                    const data = datas[index];
                    if(data.length >= 6){
                        const lTraining = data[1];
                        if(lTrainings.indexOf(lTraining) === -1){
                            lTrainings.push(lTraining);
                        }
                    }
                }
                setTrainings(lTrainings);
            })
        }
        

    }, [sheet, file])

    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0]);
            readSheetNames(e.target.files[0]).then((sheetNames) => {
                setSheets(sheetNames)
            })
        }
    }



    async function submit() {
        if (selectedTrainings.length === 0) {
            setCError(1);
        }
        else {
            props.handleSubmit(file, sheet, selectedTrainings);
        }
    }

    function handleAddCourse(pId: number, pEvent: React.ChangeEvent<HTMLInputElement>) {
        const lAdd: boolean = pEvent.target.checked;
        let lNewSelected: number[] = [];
        let lIndex = selectedTrainings.indexOf(pId);
        if (pEvent.target.checked && lIndex === -1) {
            lNewSelected = lNewSelected.concat(selectedTrainings, pId);
        }
        else if (lIndex !== -1) {
            if (lIndex === 0) {
                lNewSelected = lNewSelected.concat(selectedTrainings.slice(1));
            }
            else if (lIndex === selectedTrainings.length - 1) {
                lNewSelected = lNewSelected.concat(selectedTrainings.slice(0, -1));
            }
            else {
                lNewSelected = lNewSelected.concat(selectedTrainings.slice(0, lIndex), selectedTrainings.slice(lIndex + 1),);
            }
        }
        setSelectedTrainings(lNewSelected);
        if (lNewSelected.length > 0) {
            setCError(0);
        }
    }

    return (
        <Dialog
            open={true}
        >
            <DialogTitle>
                {props.title === undefined ?
                    'Importer un fihier'
                    :
                    props.title
                }
            </DialogTitle>
            <DialogContent>
                <FormControl fullWidth>
                    <input
                        type="file"
                        onChange={onSelectFile}
                    />
                </FormControl>
                <FormControl fullWidth sx={{ mt: '10px' }}>
                    <InputLabel id="label">Feuille</InputLabel>
                    <Select
                        labelId="label"
                        id="type-select"
                        value={sheet}
                        label="Feuille"
                        onChange={(event) => setSheet(event.target.value)}
                    >
                        {
                            sheets.map((lSheet) => (
                                <MenuItem value={lSheet}>{lSheet}</MenuItem>
                            ))
                        }

                    </Select>
                </FormControl>
                <FormControl>
                    <FormGroup>
                        {trainings.map((training) => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedTrainings.indexOf(training) >= 0}
                                        onClick={
                                            (event) => handleAddCourse(training, event)
                                        }
                                    />
                                }
                                label={training}
                            />
                        ))

                        }
                    </FormGroup>
                </FormControl>
                {error > 0 ?
                    <Alert severity="error">
                        {"Format de fichier invalide : " + message}
                    </Alert>
                    :
                    null
                }
                {wait ?
                    <Alert severity="info">{"Transfert du fichier en cours..."}</Alert>
                    :
                    null
                }
                {cError === 1 ?
                    <Alert severity="error">{"Aucun parcours sélectionné"}</Alert>
                    :
                    null
                }

            </DialogContent>
            <DialogActions>
                <Button disabled={wait} onClick={() => submit()}>Valider</Button>
                <Button disabled={wait} onClick={() => props.handleCancel()}>Annuler</Button>
            </DialogActions>
        </Dialog>
    );
}