import * as React from 'react';


import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import CharismaRating from '../components/CharismaRating.tsx'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';




export default function CharismaStudentTrainingPeriodicFormSection(props){

       
    const [comment, setComment] = React.useState(props.section.comment);
    const [improve, setImprove] = React.useState(props.section.improveComment);
    const [studentComment, setStudentComment] = React.useState(props.section.studentComment);
    const [note, setNote] = React.useState(props.section.note);
    
    function handleSetComment(pValue :  string){
        setComment(pValue);
       
    }
   
    function handleBlur(pType : number){
        if(pType === 0){
            props.handleSetComment(comment, props.section.id, "comment");     
        }
        else if(pType === 1){
            props.handleSetComment(improve, props.section.id, "improveComment");     
        }
        else if(pType === 2){
            props.handleSetComment(studentComment, props.section.id, "studentComment");     
        }
       
    }
    
    
    function createSubItems(pItems){
        return(
            <TableCell>
                {
                    pItems.map((item)=>(
                        <Typography>{item.title}</Typography>  
                    ))
                }
            </TableCell>
        );
    }
    
    function handleChange(pId:number, pValue : number){
        setNote(pValue);
        props.handleChangeNote(pValue,pId);
    }
    
    function createItemsTable(pItems){
        return(
            <Table>
                <TableHead>
                    <TableRow>
                        {
                            pItems.map((item)=>(
                                <TableCell>
                                    {item.title}
                                </TableCell>
                            ))
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                    {
                        pItems.map((item)=>(
                            createSubItems(item.items)  
                        ))
                    }
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2}>
                        <CharismaRating
                            value={note}
                            id={props.section.id}
                            onChange={handleChange}
                            readOnly={
                                props.readOnly || 
                                (props.profile === 2 && props.userProfile !== 2) ||
                                (props.profile === 1 && (props.userProfile !== 0 && props.userProfile !== 1))
                            }
                            max={5}
                        />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2}>
                            {createComment()}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2}>
                            {createImproveComment()}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2}>
                            {createStudentComment()}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        )
    }
        
    
    function createComment(){
        return(
            <TextField
                id="comment"
                name="comment"
                fullWidth
                multiline
                label={props.profile === 1 ? "Formateur" : "Tuteur"}
                value={comment}
                rows={5}    
                disabled={
                            props.readOnly || 
                            props.userProfile === 3 || 
                            (props.profile === 2 && props.userProfile !== 2) ||
                            (props.profile === 1 && (props.userProfile !== 0 && props.userProfile !== 1))
                        }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleSetComment(event.target.value);
                }}     
                onBlur={()=>handleBlur(0)}   
            />
            
        );
    }
    
    function createImproveComment(){
        return(
            <TextField
                id="improve"
                name="improve"
                fullWidth
                multiline
                label="Amélioration"
                value={improve}
                rows={5}    
                disabled={
                            props.readOnly || 
                            props.userProfile === 3 || 
                            (props.profile === 2 && props.userProfile !== 2) ||
                            (props.profile === 1 && (props.userProfile !== 0 && props.userProfile !== 1))
                        }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setImprove(event.target.value);
                }}     
                onBlur={()=>handleBlur(1)}   
            />
            
        );
    }
    
    function createStudentComment(){
        return(
            <TextField
                id="studentComment"
                name="studentComment"
                fullWidth
                multiline
                label="Stagiaire"
                value={studentComment}
                rows={5}    
                disabled={props.userProfile !== 3}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setStudentComment(event.target.value);
                }}     
                onBlur={()=>handleBlur(2)}   
            />
            
        );
    }    
                                   
    return(
            <Card>
                <CardHeader
                    title={props.section.title}
                    subheader={props.section.info}
                />
                <CardContent>
                    {createItemsTable(props.section.items)}
                </CardContent>
            </Card>      
    );
}