import * as React from 'react';

import AddIcon from '@mui/icons-material/Add'
import CharismaEventForm from './eventForm.tsx';
import CharismaEventFormReadOnly from './eventFormReadOnly.tsx';
import CharismaEvent from './event.tsx'
import { CharismaUserApi } from '../api/users/CharismaUserApi.tsx';
import { CharismaEventApi } from '../api/events/CharismaEventApi.tsx';
import { CharismaUserContext } from '../context.tsx';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Box from '@mui/material/Box'
import "dayjs/locale/fr";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CharismaSchedulerFileForm from './schedulerFileForm.tsx';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography';

import {formatTime} from '../utils.tsx'




export default function CharismaScheduler(props){
    
    const [events, setEvents] = React.useState([]);
    const [currentEvent, setCurrentEvent] = React.useState(null);
    const [teachers, setTeachers] = React.useState([]);
    const [create, setCreate] = React.useState(false);
    const [date, setDate] = React.useState({$d:new Date()});
    const [days, setDays] = React.useState([]);
    const [uploadFile, setUploadFile] = React.useState(false);

    const lCurrentUser = React.useContext(CharismaUserContext);
    const lReadWrite = lCurrentUser !== null && lCurrentUser !== undefined && lCurrentUser.profile === 0;
    

    function toDate(pDate: Date){
        var year = pDate.toLocaleString("default", { year: "numeric" });
        var month = pDate.toLocaleString("default", { month: "2-digit" });
        var day = pDate.toLocaleString("default", { day: "2-digit" });
        return year + '-' + month + '-' + day;
    }
    
    React.useEffect(()=>{
        
        const lResponse = CharismaUserApi.List(1, 0);
        lResponse.then((value)=>{
            if(value.error === 0){
                setTeachers(value.users);
            }
        }).catch((error)=>{
            
        })
    }, [])
    
    React.useEffect(()=>{
        let lCurrentDate = date['$d'];
        let lDayOfWeek = lCurrentDate.getDay();
        let lMonday = new Date(lCurrentDate);
        if(lDayOfWeek === 0){
            lMonday.setDate(lCurrentDate.getDate() - 6);
        }
        else{
            lMonday.setDate(lCurrentDate.getDate() - (lDayOfWeek - 1));
        }
        let lDays : Date[] = [];
        for(let lIndex = 0; lIndex < 5; lIndex++){
            let lDate = new Date(lMonday);
            lDate.setDate(lMonday.getDate() + lIndex);
            lDate.setHours(12,0,0,0);
            lDays.push(lDate);
        }        
        setDays(lDays);
        if(lDays.length > 0){

            let lStart = lDays[0].toISOString().split('T')[0];
            let lEnd = lDays[lDays.length -1 ].toISOString().split('T')[0];
            let lCourses = [];
            if(props.courseId !== undefined){
                lCourses.push(props.courseId);
            }
            const lResponseEvents = CharismaEventApi.ListEvents(lStart, lEnd, props.trainingId, lCourses)
                lResponseEvents.then((value)=>{
                    if(value.error === 0){
                        setEvents(value.events);                  
                    }
                }).catch((error)=>{            
                })
        }
    }, [date, props.trainingId])
    
   



    
    function onEditEvent(pEventId : number){
        const lEventResponse = CharismaEventApi.GetEvent(pEventId)
        lEventResponse.then((value)=>{
                    if(value.error === 0){
                        setCurrentEvent(value.event);                  
                    }
                }).catch((error)=>{            
        });
    }
    
  
    function handleCancel(){
        setCreate(false);
        setCurrentEvent(null);
    }
    
    function handleSubmit(pPlace : string, pStartHour : string, pEndHour:string, pSelectedCourses : number[], pDate : string, 
                          pFrequency: number, pToDate : string, pTeacher : number, pDescription  :string){
        const lResponse = CharismaEventApi.CreateEvent(pPlace, pStartHour, pEndHour,pSelectedCourses, pDate, pFrequency, pToDate, pTeacher, props.trainingId, pDescription);
        lResponse.then((value)=>{
            if(value.error === 0){
                setCreate(false);
            }
            else{
            
            }
        }).catch((error)=>{
            console.log("catch error")
        })    
        setCreate(false);
    }
    
    function handleUpdate(pPlace : string, pStartHour : string, pEndHour:string, pSelectedCourses : number[], pDate : string, pToDate:string, pFrequency: number, pTeacher : string, pDescription:string, pEventId:number, pGroupId:number){      
        const lResponse = CharismaEventApi.UpdateEvent(pPlace, pStartHour, pEndHour,pSelectedCourses, pDate, pToDate, pFrequency, pTeacher, pDescription, pEventId, pGroupId);
        lResponse.then((value)=>{
            if(value.error === 0){
                setCurrentEvent(null);
            }
            else{
            
            }
        }).catch((error)=>{
            console.log("catch error")
        })    
    }
    
    function cancelUploadFile(){
        setUploadFile(false);
    }
    
    function createEvents(pDay : string, pMorning : boolean){
        let lEvents = events.filter((lEvent)=>new Date(lEvent.date).setHours(12,0,0,0) === pDay.getTime());      
        if(pMorning){
            lEvents = lEvents.filter((lEvent)=>lEvent.startHour < "12:00:00");
        }
        else{
            lEvents = lEvents.filter((lEvent)=>lEvent.startHour >= "12:00:00");
        }  
        return(
            lEvents.map((lEvent)=>(
              <CharismaEvent
                event={lEvent}
                onEdit={onEditEvent}
                readWrite={lReadWrite}
              />  
            ))
        )
    }
    
    function validUploadFile(pFile : File, pSheet : string, pTrainings : string[]){
        if(days.length > 0){
            let lStart = days[0].toISOString().split('T')[0];
            let lEnd = days[days.length -1 ].toISOString().split('T')[0];
            const lResponse = CharismaEventApi.UploadFile(pFile, pSheet, props.trainingId, pTrainings, lStart, lEnd)
            lResponse.then((value)=>{
                if(value.error === 0){
                    if(value.events !== null){
                        setEvents(value.events);
                    }
                    setUploadFile(false);
                }
                else{
                
                }
            }).catch((error)=>{
                console.log("catch error")
            })
        }    
    }
    
    return(
        <Box>
            <Toolbar>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                    <DatePicker value={date} onChange={(value)=>setDate(value)} />
                </LocalizationProvider>
                {lReadWrite ?
                <div>
                <IconButton onClick={()=>setCreate(true)}>
                    <AddIcon/>
                </IconButton>
                <IconButton onClick={()=>setUploadFile(true)}>
                    <FileUploadIcon/>
                </IconButton>
                </div>
                :
                null} 
            </Toolbar>
            <TableContainer style={{maxWidth:'100%', overflowX: 'auto'}}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow key="days">
                        <TableCell key="hours">
                        </TableCell>
                        {
                            days.map((lDay)=>(
                                <TableCell align='center'>{lDay.toLocaleString('fr-fr', {weekday:'long', day:'numeric', month:'numeric'})}</TableCell>
                            ))
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow  sx={{verticalAlign:'top'}}>
                    <TableCell width={100} align='center' sx={{borderRight:'1px solid lightgray'}}>
                        Matin
                    </TableCell>
                    {days.map((lDay)=>(
                        <>
                        <TableCell width={400} sx={{borderRight:'1px solid lightgray'}}>
                            <div>
                            {createEvents(lDay, true)}
                            </div>
                        </TableCell>
                        </>        
                    ))
                    }
                    </TableRow>
                    <TableRow sx={{verticalAlign:'top'}}>
                    <TableCell width={100} align='center' sx={{borderRight:'1px solid lightgray'}}>
                        Après-Midi
                    </TableCell>
                    {days.map((lDay)=>(
                        <>
                        <TableCell width={400} sx={{borderRight:'1px solid lightgray'}}>
                            <div>
                            {createEvents(lDay, false)}
                            </div>
                        </TableCell>
                        </>        
                    ))
                    }
                    </TableRow>
                </TableBody>            
            </Table>
            </TableContainer>
            {create || currentEvent !== null && lReadWrite?
                <CharismaEventForm
                    title={create? 'Créer un événement' : 'Modifier un évenement'}
                    courses={props.courses}
                    handleCancel={handleCancel}
                    handleSubmit={handleSubmit}
                    handleUpdate={handleUpdate}
                    minDate={props.minDate}
                    maxDate={props.maxDate}
                    teachers={teachers}  
                    event={currentEvent}
                    modify={currentEvent !== null}
                />
                :
                null
            }
            {currentEvent !== null && !lReadWrite?
                <CharismaEventFormReadOnly
                    title={'Consuler un évenement'}
                    handleCancel={handleCancel}  
                    event={currentEvent}
                />
                :
                null
            }
            {uploadFile ?
                <CharismaSchedulerFileForm
                    handleCancel={cancelUploadFile}
                    handleSubmit={validUploadFile}
                    courses={props.courses}
                />
                :
                null
            }
        </Box>  
    );
}