
import { CharismaApi } from "../CharismaApi.js";

export const CharismaEventApi = {
    CreateEvent: async function(pPlace : string, pStartHour:string, pEndHour:string, pSelectedCourses : number[], pDate : string, pFrequency: number, 
                                pToDate : string, pTeacher : string, pTrainingId : number, pDescription : string){
        const lDatas = new FormData();
        lDatas.append('place', pPlace);
        lDatas.append('startHour', pStartHour);
        lDatas.append('endHour', pEndHour);
        lDatas.append('date', pDate);
        lDatas.append('frequency', pFrequency);
        lDatas.append('toDate', pToDate);
        lDatas.append('trainingId', pTrainingId);
        lDatas.append('description', pDescription);
        pSelectedCourses.forEach((lCourse)=>{
            lDatas.append("coursesId[]", lCourse);    
        });
        lDatas.append('teacher', pTeacher)
        const lResponse = await CharismaApi.request({
            url:`/events/createEvent.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    ListEvents: async function(pStartDate : string, pEndDate : string, pTrainingId: number, pCourses: number[]){
        const lResponse = await CharismaApi.request({
            url:`/events/listEvents.php`,
            method:"GET",
            params:{
                startDate:pStartDate,
                endDate: pEndDate,
                trainingId : pTrainingId,
                coursesId:pCourses
            }
        })
        return lResponse.data;
    },
    GetEvent: async function(pEventId : number){
        const lResponse = await CharismaApi.request({
            url:`/events/getEvent.php`,
            method:"GET",
            params:{
                eventId:pEventId
            }
        })
        return lResponse.data;
    },
    UpdateEvent: async function(pPlace : string, pStartHour:string, pEndHour:string, pSelectedCourses : number[], pDate : string, pToDate: string, pFrequency:number, pTeacher : string, pDescription: string, pEventId : number, pGroupId: number){
        const lDatas = new FormData();
        lDatas.append('place', pPlace);
        lDatas.append('startHour', pStartHour);
        lDatas.append('endHour', pEndHour);
        lDatas.append('date', pDate);
        lDatas.append('toDate', pToDate);
        lDatas.append('frequency', pFrequency);
        lDatas.append('description', pDescription);
        lDatas.append('eventId', pEventId);
        lDatas.append('groupId', pGroupId);
        pSelectedCourses.forEach((lCourse)=>{
            lDatas.append("coursesId[]", lCourse);    
        });
        lDatas.append('teacher', pTeacher)
        const lResponse = await CharismaApi.request({
            url:`/events/updateEvent.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    UploadFile: async function(pFile : File, pSheet : string, pTrainingId : number , pTrainings : string[], pStart : string, pEnd : string ){
        const lDatas = new FormData();
        lDatas.append('trainingId', pTrainingId);        
        lDatas.append('start', pStart);
        lDatas.append('end', pEnd);
        lDatas.append('file', pFile);
        lDatas.append('sheet', pSheet);
        pTrainings.forEach((lCourse)=>{
            lDatas.append("trainings[]", lCourse);    
        });        
        const lResponse = await CharismaApi.request({
            url:`/events/upload.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
}