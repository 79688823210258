import * as React from 'react';

import Avatar from '@mui/material/Avatar'
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box'
import ListItem from '@mui/material/ListItem';

import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';



import { CharismaUserContext } from '../context.tsx';
import { formatDate } from '../utils.tsx';


export default function CharismaStudentsListItem(props){
    
    const [course, setCourse] = React.useState(props.course);
    const [reason, setReason] = React.useState(props.reason === null ? 0 : props.reason);
    const [student, setStudent] = React.useState(props.student);
    const [subscribe, setSubscribe] = React.useState(props.subscribe);
    const [tutor, setTutor] = React.useState(props.tutor);
    const [global, setGlobal] = React.useState(props.global);
    
    

//    const lCurrentUser = React.useContext(CharismaUserContext);

    
    React.useEffect(()=>{

        setReason(props.reason === null ? 0 : props.reason);       
        setStudent(props.student);           
        setTutor(props.tutor);    
        setCourse(props.course);
        setSubscribe(props.subscribe);
        setGlobal(props.global);
    }, [props.reason, props.student, props.tutor,  props.course, props.subscribe, props.global])
    
    //const lTrash : boolean = props.trash === undefined ? false : true; 

    
    const lReasons=["En Cours","Certifié", "Abandon", "Echec"]
    const lSubscribes =["Incomplet", "A Valider", "Valide"];

    return(               
        <ListItem 
            alignItems='flex-start'
            onClick={()=>props.handleSelect(student.id, props.id)}
            sx={{background:props.selected ? "lightgray" : null, width:{xs:"300px"}}}
        >
            <ListItemAvatar>
            <Badge
                color={global === 0 ? "default" : global === 1 ? "error" : global ===2 ? "warning" : "success"}
                badgeContent=" "                    
            >
                {
                    student.avatar !== null ?
                    
                    <Avatar
                        src={student.avatar}
                    />
                    :          
                    <Avatar>
                        {student.firstName[0] + student.lastName[0]}
                    </Avatar>
                }            
            </Badge>
            </ListItemAvatar>
            <ListItemText
                primary={
                    <Box display="flex" justifyContent="end-right">
                        <Typography>{student.firstName + ' ' + student.lastName}</Typography>
                    </Box>
                }
                secondary={
                    <Box>
                    <Typography>{"Tuteur : " + tutor.firstName + ' ' + tutor.lastName}</Typography>
                    <Typography>{"Inscription : " + lSubscribes[subscribe]}</Typography>
                    <Typography>{"Parcours : " + course.course}</Typography>
                    <Typography>{"Statut : " + lReasons[reason]}</Typography>                                          
                    <Typography>{"Connexion : " + formatDate(student.lastConnection)}</Typography>
                    </Box>
                }
            />            
        </ListItem>                                
    );
    
    
}