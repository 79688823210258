import * as React from 'react';

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField';


import { ValidateString } from '../utils/Validator.tsx';

export default function CharismaEvaluationTemplateItemForm(props){
    
    const [title, setTitle] = React.useState(props.item !== undefined ? props.item.title : "");
    const [comment, setComment] = React.useState(props.item !== undefined ? props.item.comment : "");
    const [type, setType] = React.useState(props.item !== undefined ? props.item.type : 1);
    const [max, setMax] = React.useState(props.item !== undefined ? props.item.max : "");
    const [nError, setNError] = React.useState(0);
    const [mError, setMError] = React.useState(0);
    const [wait, setWait] = React.useState(false);

    
    function handleSubmit(){
        const lValidName: boolean = title.trim().length;
        if(!lValidName){
            setNError(1);
        }
        
        
        let lValidMax = true;
        if(props.templateType === 0  && type === 2){
            lValidMax = parseInt(max) > 0;
        }
        
        if(!lValidMax){
            setMError(1);
        }
        
        
        if(lValidMax && lValidName && props.id === undefined){
            setWait(true);
            props.handleSubmit(title, comment, type, props.parentId, max);
        }
        else if(lValidMax && lValidName){
            setWait(true);
            props.handleSubmit(title, comment, type, max, props.id);
        }
        
    }
    
    function checkInt(pValue : string){
        let lValue : number = parseInt(pValue);
        if(lValue > 0 ){
            setMax(lValue);
        } 
        else if(pValue.length===0){
            setMax("");
        }
    }
    
    return(
        <Dialog
            open={true}
        >
            <DialogTitle>
                {props.title === undefined ?
                    'Modification du rapport'
                    :
                    props.title
                }
            </DialogTitle>
            <DialogContent>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Nom"
                    name="type"
                    autoFocus
                    error={nError > 0}
                    helperText={nError > 0 ? 'Nom invalide' : null}
                    value={title}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setTitle(event.target.value);
                        setNError(0);
                    }}
                />                   
                <TextField
                    margin="normal"
                    fullWidth
                    id="name"
                    multiline
                    rows={5}
                    label="Commentaire"
                    name="comment"
                    value={comment}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setComment(event.target.value)
                    }}
                />                
                {props.templateType === 0 ?
                <FormControl fullWidth>
                <InputLabel id="label">Type de Champs</InputLabel>
                    <Select
                        labelId="label"
                        id="type-select"
                        value={type}
                        label="Type"
                        onChange={(event)=>setType(event.target.value)}
                    >
                    <MenuItem value={1}>Evaluation</MenuItem>
                    <MenuItem value={2}>Note</MenuItem>
                  </Select>
                </FormControl>                             
                :
                null
                }
                {props.templateType === 0  && type === 2?
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="max"
                    label="Note Max"
                    name="max"
                    autoFocus
                    error={mError > 0}
                    helperText={mError > 0 ? 'Valeur invalide' : null}
                    value={max}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        checkInt(event.target.value);
                        setMError(0);
                    }}
                />                              
                :
                null
                }              
                {wait ?
                    <Alert severity="info">{props.id===undefined ? "Création en cours":"Modification en cours"}</Alert>
                    :
                    null
                }
            </DialogContent>
            <DialogActions>
                <Button disabled={wait} onClick={()=>handleSubmit()}>Valider</Button>
                <Button disabled={wait} onClick={()=>props.handleCancel()}>Annuler</Button>
            </DialogActions>
        </Dialog>        
    );
}