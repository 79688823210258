import * as React from 'react';

import Box from '@mui/material/Box'
import InputAdornment from '@mui/material/InputAdornment';
import CharismaRating from '../components/CharismaRating.tsx'
import TextField from '@mui/material/TextField';


export default function CharismaStudentTrainingEvaluationFormItems(props){
    
    
    const [item, setItem] = React.useState(props.item);
    const [note, setNote] = React.useState(props.item !== undefined ? props.item.note : 0);
    
        
    
    function handleChange(pItemId : number, pValue : number){
        let lItem = item;
        lItem.note = pValue;
        setItem(lItem);
        props.handleSetValue(pValue, item.id);
    }
    
    function handleBlur(){
        props.handleSetValue(note, item.id);   
    }

        
    function setItemNote(pValue :  string){
        let lValue : number = parseInt(pValue);
        let lValid = true;
        if(lValue < 0 || lValue > item.max ){
            lValid = false;
        } 
        else if(pValue.length===0){
            lValue = 0;
        }
        else if(isNaN(lValue)){
            lValid = false;
        }
        if(lValid){
            setNote(lValue);            
        }
    }    

                                              
    return(
        <Box>
        {item.type === 1 ?
            <CharismaRating
                value={item.note}
                id = {item.id}
                onChange={handleChange}
                readOnly={props.readOnly}
                max={5}
             />
             :
         item.type === 2 ?
            <TextField
                id={item.id}
                name={item.title}
                value={note}
                disabled={props.readOnly}
                InputProps={{
                    endAdornment: <InputAdornment position="end">{"/" + item.max}</InputAdornment>,
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setItemNote(event.target.value);
                }}
                onBlur={()=>handleBlur()}
            />
            :
            null
        }
        </Box>            
    );
}