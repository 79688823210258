import * as React from 'react';

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import { ValidateDate, ValidateDateRange, ValidateString } from '../utils/Validator.tsx';

export default function CharismaExamForm(props){
    
    const [name, setName] = React.useState(props.name);
    const [nError, setNError] = React.useState(0);
    const [date, setDate] = React.useState(props.date);
    const [dateError, setDateError] = React.useState(0);
    const [templateId, setTemplateId] = React.useState(props.templateId);
    const [tError, setTError] = React.useState(0);
    const [examinators, setExaminators] = React.useState([]);
    const [eError, setEError] = React.useState(0);
    const [students, setStudents] = React.useState([]);     
    const [sError, setSError] = React.useState(0);
    const [wait, setWait] = React.useState(false);
    
    function handleSubmit(){
        const lValidName: boolean = ValidateString(name, 100);
        if(!lValidName){
            setNError(1);
        }
        
        let lValidDate: number = ValidateDate(date);
        if(lValidDate === 0 && props.minDate !== undefined){
            lValidDate = ValidateDateRange(props.minDate, date);
            if(lValidDate === 1){
                setDateError(2);
            }                        
        }
        else{
            setDateError(lValidDate);
        }
        
        if(lValidDate === 0 && props.maxDate !== undefined){
            let lValidMax = ValidateDateRange(date, props.maxDate);
            if(lValidMax === 1){
                setEError(3);
                lValidDate = 1;
            }
            else{
                setEError(0);
            }
        }
        
        let lValidTemplate = templateId !== 0;
        if(!lValidTemplate){
            setTError(1);
        }
        else{
            setTError(0);
        }
        
        let lValidExams = examinators.length > 0;
        if(!lValidExams){
            setEError(1);
        }
        else{
            setEError(0);
        }

        let lValidStudents = students.length > 0;
        if(!lValidStudents){
            setSError(1);
        }
        else{
            setSError(0);
        }
        
        if(lValidName && lValidDate === 0 && lValidTemplate && lValidExams && lValidStudents){
            props.handleSubmit(name, templateId, date, examinators, students);
        }
    }
    
    function handleSelectedExaminator(pEvent : React.ChangeEvent<HTMLInputElement>, pId : number){
        let lNewSelected : number[] = [];
        let lIndex = examinators.indexOf(pId);
        if(pEvent.target.checked && lIndex === -1){
            lNewSelected = lNewSelected.concat(examinators, pId);
        }
        else if(lIndex !== -1){
            if(lIndex === 0){
                lNewSelected = lNewSelected.concat(examinators.slice(1));        
            }
            else if(lIndex === examinators.length - 1){
                lNewSelected = lNewSelected.concat(examinators.slice(0, -1));
            }
            else{
                lNewSelected = lNewSelected.concat(examinators.slice(0, lIndex), examinators.slice(lIndex + 1),);
            }
        }
        setExaminators(lNewSelected);
    }
    
    function handleSelectedStudent(pEvent : React.ChangeEvent<HTMLInputElement>, pId : number){
        let lNewSelected : number[] = [];
        let lIndex = students.indexOf(pId);
        if(pEvent.target.checked && lIndex === -1){
            lNewSelected = lNewSelected.concat(students, pId);
        }
        else if(lIndex !== -1){
            if(lIndex === 0){
                lNewSelected = lNewSelected.concat(students.slice(1));        
            }
            else if(lIndex === examinators.length - 1){
                lNewSelected = lNewSelected.concat(students.slice(0, -1));
            }
            else{
                lNewSelected = lNewSelected.concat(students.slice(0, lIndex), students.slice(lIndex + 1),);
            }
        }
        setStudents(lNewSelected);
    }    
        
    return(
        <Dialog
            open={true}
            fullWidth={true}
            maxWidth={"lg"}
        >
            <DialogTitle>
                {props.title === undefined ?
                    'Modification du parcours'
                    :
                    props.title
                }
            </DialogTitle>
            <DialogContent>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Nom"
                    name="fName"
                    autoFocus
                    error={nError > 0}
                    helperText={nError > 0 ? 'Nom invalide' : null}
                    value={name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setName(event.target.value);
                        setNError(0);
                    }}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="startDate"
                    label="Début"
                    name="startDate"
                    InputLabelProps={{ shrink: true, required: true }}
                    type={'date'}
                    error={dateError > 0}
                    helperText={dateError === 1 ? 'Date invalide' : dateError === 2 ? 'Date antérieure à la date de début de la formation' : null}
                    value={date}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setDate(event.target.value);
                    }}
                />
                <FormControl fullWidth>
                <InputLabel id="label">Evaluation</InputLabel>
                    <Select
                        labelId="label"
                        id="type-select"
                        value={templateId}
                        label="Evaluation"
                        onChange={(event)=>setTemplateId(event.target.value)}
                    >
                    {
                        props.evals.map((lEval)=>(
                            <MenuItem value={lEval.id}>{lEval.title}</MenuItem> 
                        ))
                    }
                  </Select>
                </FormControl>             
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                    <InputLabel id="label">Examinateurs</InputLabel>
                    <FormGroup>
                        {props.examinators.map((lExaminator)=>(
                            <FormControlLabel
                            control = {<Checkbox 
                                checked={examinators.indexOf(lExaminator.id) >= 0}
                                onClick={                                    
                                    (event)=>handleSelectedExaminator(event, lExaminator.id)
                                } 
                            />}
                            label={lExaminator.firstName + " " + lExaminator.lastName}
                            />

                        ))
                        }
                     </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <InputLabel id="label">Stagiaires</InputLabel>
                    <FormGroup>
                        {props.students.map((lStudent)=>(
                            <FormControlLabel
                            control = {<Checkbox 
                                checked={students.indexOf(lStudent.id) >= 0}
                                onClick={                                    
                                    (event)=>handleSelectedStudent(event, lStudent.id)
                                } 
                            />}
                            label={lStudent.student.firstName + " " + lStudent.student.lastName}
                            />

                        ))
                        }
                     </FormGroup>
                    </Grid>                    
                </Grid>

                {wait ?
                    <Alert severity='info'>{props.id === undefined ? "Création du parcours en cours" : "Modification en cours"}</Alert>
                    :
                    null
                }
            </DialogContent>
            <DialogActions>
                <Button disabled={wait} onClick={()=>handleSubmit()}>Valider</Button>
                <Button disabled={wait} onClick={()=>props.handleCancel()}>Annuler</Button>
            </DialogActions>
        </Dialog>        
    );
}