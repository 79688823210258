import * as React from 'react';

import AddIcon from '@mui/icons-material/Add'
import Alert from '@mui/material/Alert';

import Box from '@mui/material/Box'
import Button from "@mui/material/Button"
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { CharismaDocumentsApi } from '../api/documents/CharismaDocumentsApi.tsx';
import CharismaFileForm from '../components/fileForm.tsx';
import CharismaNameForm from '../components/nameForm.tsx';
import { CharismaUserContext } from '../context.tsx';
import Checkbox  from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import '../globals.js'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase'
import Link from '@mui/material/Link';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import {formatDate} from '../utils.tsx'

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export default function CharismaDocumentsForm(props){
    
    
    const [path, setPath] = React.useState('');
    const [dirs, setDirs] = React.useState([]);
    const [files, setFiles] = React.useState([]);
    const [searchDirs, setSearchDirs] = React.useState('');
    const [searchFiles, setSearchFiles] = React.useState('');
    const [selectedFiles, setSelectedFiles] = React.useState([]);
    const [wait, setWait] = React.useState(false);

    
    //const lCurrentUser = React.useContext(CharismaUserContext);
    const lReadWrite = true;
    
    React.useEffect(()=>{
        const lResponse = CharismaDocumentsApi.Scan(path);
        lResponse.then((value)=>{
            if(value.error === 0){
                setDirs(value.directories);
                setFiles(value.files);
                setSelectedFiles([]);
                setSearchDirs('');
                setSearchFiles('');
            }
        }).catch((error)=>{
            
        })
    },[path])
    

    
    
    
    
    function openFile(pFile:string){
        window.open(pFile, "_blank")
    }
    
    function handleSelectedFile(pEvent : React.ChangeEvent<HTMLInputElement>, pFileId : number){
        let lNewSelected : number[] = [];
        let lIndex = selectedFiles.indexOf(pFileId);
        if(pEvent.target.checked && lIndex === -1){
            lNewSelected = lNewSelected.concat(selectedFiles, pFileId);
        }
        else if(lIndex !== -1){
            if(lIndex === 0){
                lNewSelected = lNewSelected.concat(selectedFiles.slice(1));
            }
            else if(lIndex === selectedFiles.length - 1){
                lNewSelected = lNewSelected.concat(selectedFiles.slice(0, -1));
            }
            else{
                lNewSelected = lNewSelected.concat(selectedFiles.slice(0, lIndex), selectedFiles.slice(lIndex + 1),);
            }
        }
        setSelectedFiles(lNewSelected);
    }
       
    
    let lPathes : string[] = path.split('/').filter((lPath)=>lPath !== "");
    let lBreadcrumbsLink = "";
    let lBrItems=[];
    
    lPathes.forEach((lPath)=>{
        lBreadcrumbsLink = lBreadcrumbsLink + '/' + lPath;
        lBrItems.push({'path':lPath, 'link':lBreadcrumbsLink});
    });

    const lDirs = dirs.filter((dir)=>(dir.name.toLowerCase().includes(searchDirs.toLowerCase())));
    const lFiles = files.filter((file)=>(file.name.toLowerCase().includes(searchFiles.toLowerCase())));

    return(
        <Dialog
            open={true}
            fullWidth
            maxWidth='xl'
        >
            <DialogTitle>
                {props.title}
            </DialogTitle>
            <DialogContent>
                <Box>
                    <Typography variant="h5">Ajout de documents</Typography>
                    <Toolbar>
                        <Breadcrumbs aria-label="breadcrumb" separator=">">
                           <Link 
                                onClick={()=>setPath("")}
                            >
                            Documents
                            </Link>
                            {
                                lBrItems.map((lItem)=>(
                                    <Link 
                                        onClick={()=>setPath(lItem.link)}
                                    >
                                    {lItem.path}
                                    </Link>
                                ))
                            }
                        </Breadcrumbs>
        
        
        
                    </Toolbar>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={8}>
                            <Toolbar>
                                <Typography>Fichiers</Typography>                                                                       
                            </Toolbar>
                            <Search>
                                <SearchIconWrapper>
                                    <SearchIcon />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Rechercher…"
                                    inputProps={{ 'aria-label': 'search' }}
                                    onChange={(event)=>setSearchFiles(event.target.value)}
                                    value={searchFiles}
                                />
                            </Search>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {lReadWrite ?
                                            <TableCell padding="checkbox">
                                            </TableCell>
                                            :
                                            null
                                        }
                                        <TableCell>Nom</TableCell>
                                        <TableCell>Ajouté par</TableCell>
                                        <TableCell>Mis à jour le</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>                      
                                {
                                    lFiles.map((file)=>(
                                        <TableRow key={file.name}>
                                            {lReadWrite ?
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={selectedFiles.indexOf(file.id) >= 0}
                                                        onClick={(event)=>handleSelectedFile(event, file.id)}
                                                    />
                                                </TableCell>
                                                :
                                                null
                                            }
                                            <TableCell>
                                                <Link onClick={()=>openFile(file.link)}>
                                                    {file.name}
                                                </Link>
                                            </TableCell>
                                            <TableCell>
                                                {file.owner}
                                            </TableCell>
                                            <TableCell>
                                                {formatDate(file.lastModified)}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }                      
                                
                                </TableBody>
                            </Table>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Toolbar>
                            <Typography>Répertoire</Typography>                                                                   
                            </Toolbar>
                            <Search>
                                <SearchIconWrapper>
                                    <SearchIcon />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Rechercher…"
                                    inputProps={{ 'aria-label': 'search' }}
                                    onChange={(event)=>setSearchDirs(event.target.value)}
                                    value={searchDirs}
                                />
                            </Search>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nom</TableCell>
                                        <TableCell>Ajouté par</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>                      
                                {
                                    lDirs.map((dir)=>(
                                        <TableRow>                                            
                                            <TableCell>
                                                <Link onClick={()=>setPath(dir.link)} >
                                                    {dir.name}
                                                </Link>
                                            </TableCell>
                                            <TableCell>
                                                {dir.owner}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }                      
                                
                                </TableBody>
                            </Table>
                        </Grid>                        
                    </Grid>    
                </Box>
             </DialogContent>
             <DialogActions>
                <Button onClick={()=>props.onCancel()}>
                    Annuler
                </Button>
                <Button disabled={selectedFiles.length === 0} onClick={()=>props.onValid(selectedFiles, path)}>
                    Ajouter
                </Button>
             </DialogActions>
        </Dialog>

    );
    
}