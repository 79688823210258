
import { CharismaApi } from "../CharismaApi.js";

export const CharismaGoogleApi = {
    Initialize: async function(){
        const lDatas = new FormData();

        const lResponse = await CharismaApi.request({
            url: `/google/initialize.php`,
            method: "POST",
            data:lDatas
        })
        return lResponse.data;
    },
    GetCalendars: async function(){

        const lResponse = await CharismaApi.request({
            url: `/google/getCalendars.php`,
            method: "GET",
            params:{}
        })
        return lResponse.data;
    }               
}