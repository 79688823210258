import * as React from 'react';


import Box from '@mui/material/Box'


import AddIcon from '@mui/icons-material/Add'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'

import { CharismaUserApi } from '../api/users/CharismaUserApi.tsx';
import { CharismaTrainingApi } from '../api/trainings/CharismaTrainingApi.tsx';
import { CharismaGoogleApi } from '../api/google/CharismaGoogleApi.tsx';
import CharismaTrainingsListItem from './trainingsListItem.tsx';
import CharismaTrainingForm from './trainingForm.tsx';
import { CharismaUserContext } from '../context.tsx';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {formatDate} from '../utils.tsx'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography';




export default function CharismaTrainingsList(props){
    
    interface training{
        id: number,
        type: number,
        name: string
    }
    const [currentTraining, setCurrentTraining] = React.useState(null);
    const [create, setCreate] = React.useState(false);
    const [trainingsList, setTrainingsList] = React.useState([]);
    const [deleteTrainings, setDeleteTrainings] = React.useState(false);
    const [restoreTrainings, setRestoreTrainings] = React.useState(false);
    const [selectedTrainings, setSelectedTrainings] = React.useState([]);
    const [error, setError] = React.useState(0);
    const [wait, setWait] = React.useState(false);
    const [calendars, setCalendars] = React.useState([]);
    const [responsibles, setResponsibles] = React.useState([]);    
    
    const lTrash : number = props.trash !== undefined ? 1 : 0;
    const lCurrentUser = React.useContext(CharismaUserContext);
    const lReadWrite : boolean = lCurrentUser !== null && lCurrentUser !== undefined && lCurrentUser.profile === 0;
    const lEdit : boolean = lCurrentUser !== null && lCurrentUser !== undefined && (lCurrentUser.profile === 0 || lCurrentUser.profile === 1);
    const lTypes : string[] = ['BPJEPS', 'CQP', 'AE'];

    React.useEffect(()=>{
        const lResponse = CharismaTrainingApi.ListTrainings(lTrash);
        lResponse.then((value)=>{
            if(value.error === 0){
                setTrainingsList(value.trainings);
                setSelectedTrainings([]);
            }
        }).catch((error)=>{
            
        })
    }, [lTrash])
    
    React.useEffect(()=>{
        const lResponse = CharismaUserApi.List(0, 0);
        lResponse.then((value)=>{
            if(value.error === 0 && value.users !== null){
                setResponsibles(value.users);
            }
        }).catch((error)=>{
            
        })
        const lResponseCalendars = CharismaGoogleApi.GetCalendars();
        lResponseCalendars.then((value)=>{
            if(value.error === 0){
                if(value.calendars !== null){                   
                    setCalendars(value.calendars);
                }
                
            }
        }).catch((error)=>{
            
        })
    },[])
    
    function handleCancel(){
        setCreate(false);
    }
    
    function handleTrashTrainings(){
        const lResponse = CharismaTrainingApi.TrashTrainings(selectedTrainings);
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.ids !== null){
                    let lTrainings = trainingsList.filter((training)=>(value.ids.indexOf(training.id) === -1));
                    setTrainingsList(lTrainings);
                }
                setSelectedTrainings([]);
                setDeleteTrainings(false);
            }
            else{
            
            }
        }).catch((error)=>{
            console.log("catch error")
        })
    }
    
    function handleDeleteTrainings(){
        const lResponse = CharismaTrainingApi.DeleteTrainings(selectedTrainings);
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.ids !== null){
                    let lTrainings = trainingsList.filter((training)=>(value.ids.indexOf(training.id) === -1));
                    setTrainingsList(lTrainings);
                }
                setSelectedTrainings([]);
                setDeleteTrainings(false);
            }
            else{
            
            }
        }).catch((error)=>{
            console.log("catch error")
        })           
    }
    
    function handleRestoreTrainings(){
        const lResponse = CharismaTrainingApi.RestoreTrainings(selectedTrainings);
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.ids !== null){
                    let lTrainings = trainingsList.filter((training)=>(value.ids.indexOf(training.id) === -1));
                    setTrainingsList(lTrainings);
                }
                setSelectedTrainings([]);
                setRestoreTrainings(false);
            }
            else{
            
            }
        }).catch((error)=>{
            console.log("catch error")
        })           
    }
    
    function handleSelectedTraining(pEvent : React.ChangeEvent<HTMLInputElement>, pId : number){
        let lNewSelected : number[] = [];
        let lIndex = selectedTrainings.indexOf(pId);
        if(pEvent.target.checked && lIndex === -1){
            lNewSelected = lNewSelected.concat(selectedTrainings, pId);
        }
        else if(lIndex !== -1){
            if(lIndex === 0){
                lNewSelected = lNewSelected.concat(selectedTrainings.slice(1));
            }
            else if(lIndex === selectedTrainings.length - 1){
                lNewSelected = lNewSelected.concat(selectedTrainings.slice(0, -1));
            }
            else{
                lNewSelected = lNewSelected.concat(selectedTrainings.slice(0, lIndex), selectedTrainings.slice(lIndex + 1),);
            }
        }
        setSelectedTrainings(lNewSelected);
    }
    
    
    
    function handleSubmit(pName : string, pType: number, pStartDate : string, pEndDate : string, pResponsible : number){
        setError(0);
        const lResponse = CharismaTrainingApi.CreateTraining(pName, pType, pStartDate, pEndDate, pResponsible);
        lResponse.then((value)=>{
            setError(value.error);
            if(value.error === 0){
                if(value.training !== null){
                    let lTrainings = trainingsList.concat(value.training);
                    setTrainingsList(lTrainings);                    
                }
                setCreate(false);
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })
    }
    
    function handleUpdate(pName : string, pType : number, pStartDate : string, pEndDate : string, pResponsible : number, pId : number, pCalendarId : string){
        setError(0);
        const lResponse = CharismaTrainingApi.UpdateTraining(pName, pType, pStartDate, pEndDate, pResponsible, pId, pCalendarId);
        lResponse.then((value)=>{
            setError(value.error);
            if(value.error === 0){
                setCurrentTraining(null);
                if(value.training !== null){
                    const lNewTraining = value.training;
                    let lTrainings : training[] = trainingsList;
                    for(let lIndex = 0; lIndex < lTrainings.length; lIndex++){
                        let lTraining = trainingsList[lIndex];
                        if(lTraining.id === lNewTraining.id){
                            lTrainings[lIndex] = lNewTraining;
                            break;
                        }
                    }
                    setTrainingsList(lTrainings);
                }
                
                
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })
    }
    
    function handleCancelUpdate(){
        setCurrentTraining(null);
    }
    
    function handleEdit(pId : number){
        const lResponse = CharismaTrainingApi.GetTraining(pId);
        lResponse.then((value)=>{
            setError(value.error);
            if(value.error === 0){
                setCurrentTraining(value.training);
                
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })  
    }

        
    return(
        
        <Box>
            <Toolbar>
                <Typography variant='h5'>
                {props.title}
                </Typography>
                <IconButton>
                    <AddIcon
                        onClick={()=>setCreate(true)}
                    />
                </IconButton>
            </Toolbar>
            <Grid container spacing={2}>

                {trainingsList.map((training)=>(
                    <Grid item xs={12} md={4}>
                        <CharismaTrainingsListItem
                            training={training}
                            handleEdit = {handleEdit}
                        />
                    </Grid>
                ))}                      
            </Grid>
            {create ?
                <CharismaTrainingForm
                    title={"Ajout d'une formation"}
                    type={0}
                    responsibles={responsibles}
                    handleSubmit={handleSubmit}
                    handleCancel={handleCancel}
                />
                :
                null    
            }    
            {
                currentTraining !== null ?
                <CharismaTrainingForm
                    title={currentTraining.name}
                    name = {currentTraining.name}
                    type={currentTraining.type}
                    startDate={currentTraining.startDate}
                    endDate={currentTraining.endDate}
                    calendar={currentTraining.calendarId}
                    calendars={calendars}
                    id={currentTraining.id}
                    handleSubmit={handleUpdate}
                    handleCancel={handleCancelUpdate}
                    responsibles={responsibles}
                    responsible={currentTraining.responsibleId}
                />
                :
                null
            }
            <Dialog
                open={deleteTrainings}
            >
                <DialogTitle>Suppression de Formation</DialogTitle>
                <DialogContent>
                    {
                        lTrash === 1 ?
                            <Typography>
                                Etes-vous sûr de vouloir supprimer définitevement les formations sélectionnées?
                            </Typography>                         
                            :
                            <Typography>
                                Etes-vous sûr de vouloir mettre les formations à la corbeille?
                            </Typography>  
                    }
                    {
                        wait?
                            <Alert severity='info'>Suppression en cours...</Alert>
                        :
                        null
                    }
                </DialogContent>
                <DialogActions>
                    <Button disabled={wait} autoFocus onClick={()=>setDeleteTrainings(false)}>
                        Annuler
                    </Button>
                    <Button disabled={wait} onClick={lTrash === 1 ? handleDeleteTrainings : handleTrashTrainings}>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog> 
            <Dialog
                open={restoreTrainings}
            >
                <DialogTitle>Restauration de Parcours</DialogTitle>
                <DialogContent>
                    <Typography>Etes-vous sûr de vouloir restaurer les parcours sélectionnés?</Typography>
                    {
                        wait?
                            <Alert severity='info'>Restauration en cours...</Alert>
                        :
                        null
                    }
                </DialogContent>
                <DialogActions>
                    <Button disabled={wait} autoFocus onClick={()=>setRestoreTrainings(false)}>
                        Annuler
                    </Button>
                    <Button disabled={wait} onClick={handleRestoreTrainings}>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>             
        </Box>      
    );
    
    
}