import * as React from 'react';


import Box from '@mui/material/Box'


import AddIcon from '@mui/icons-material/Add'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Card from'@mui/material/Card'
import CardContent from'@mui/material/CardContent'
import CardHeader from'@mui/material/CardHeader'
import { CharismaEvaluationTemplateApi } from '../api/evaluations/CharismaEvaluationTemplateApi.tsx';
import CharismaEvaluationTemplateForm from './evaluationTemplateForm.tsx';
import CharismaEvaluationTemplate from './evaluationTemplate.tsx';
import CharismaImportTemplateForm from './importTemplateForm.tsx';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit'
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';




export default function CharismaEvaluationTemplatesList(props){
    
    const [evaluationTemplates, setEvaluationTemplates] = React.useState([]);
    const [create, setCreate] = React.useState(false);
    const [upload, setUpload] = React.useState(false);
    const [currentId, setCurrentId] = React.useState(0);
    const [deleteEvaluationTemplate, setDeleteEvaluationTemplate] = React.useState(false);
    const [selected, setSelected] = React.useState([]);
    
    const lType = props.type;
    
    React.useEffect(()=>{
        const lResponse = CharismaEvaluationTemplateApi.ListEvaluationTemplates(lType);
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.evaluationTemplates !== null){
                    setEvaluationTemplates(value.evaluationTemplates);
                }
                
            }
        }).catch((error)=>{
            
        })
        setSelected([]);
        setCurrentId(0);
    }, [lType])
    
    
    function handleCancel(){
        setCreate(false);
    }
           
    function handleSelected(pEvent : React.ChangeEvent<HTMLInputElement>, pId : number){
        let lNewSelected : number[] = [];
        let lIndex = selected.indexOf(pId);
        if(pEvent.target.checked && lIndex === -1){
            lNewSelected = lNewSelected.concat(selected, pId);
        }
        else if(lIndex !== -1){
            if(lIndex === 0){
                lNewSelected = lNewSelected.concat(selected.slice(1));
            }
            else if(lIndex === selected.length - 1){
                lNewSelected = lNewSelected.concat(selected.slice(0, -1));
            }
            else{
                lNewSelected = lNewSelected.concat(selected.slice(0, lIndex), selected.slice(lIndex + 1),);
            }
        }
        setSelected(lNewSelected);
    }
    
    
    function handleSubmit(pName : string){
        const lResponse = CharismaEvaluationTemplateApi.CreateEvaluationTemplate(pName, lType);
        lResponse.then((value)=>{
            if(value.error === 0){
                setCreate(false);
                if(value.evaluationTemplate !== null){
                    let lEvaluationTemplates = evaluationTemplates.concat(value.evaluationTemplate);
                    setEvaluationTemplates(lEvaluationTemplates);
                }                                
            }
            else{
                
            }
        }).catch((error)=>{
            console.log("catch error")
        })
    }
    
    function handleUpdate(pTitle : string, pId : number){
        let lEvaluationTemplatesList = evaluationTemplates.slice();
        for(let lIndex = 0; lIndex < lEvaluationTemplatesList.length; lIndex++){
            if(pId === lEvaluationTemplatesList[lIndex].id){
                lEvaluationTemplatesList[lIndex].title = pTitle;
                break;
            }
        }
        setEvaluationTemplates(lEvaluationTemplatesList);  
    }
    
    function handleCancelUpdate(){
        setCurrentId(0);
    }
    
    function handleEdit(pId : number){
        setCurrentId(pId);
    }
    
    function cancelUpload(){
        setUpload(false);
    }
    
    function validUpload(pFile : File){
        const lResponse = CharismaEvaluationTemplateApi.ImportTemplate(pFile, lType);
        lResponse.then((value)=>{
            if(value.error === 0){
                setUpload(false);    
                let lEvaluationTemplates = evaluationTemplates.concat(value.evaluationTemplate);
                setEvaluationTemplates(lEvaluationTemplates);                   
            }
            else{
                
            }
        }).catch((error)=>{
            console.log("catch error")
        })
    }

    function updateEvaluationTemplateVisibility(pId : number, pProfile : number, pValue : number, pEvent : React.ChangeEvent<HTMLInputElement>){
        const lChecked = pEvent.target.checked;
        let lValue = pValue;
        if(lChecked){
            lValue = pValue + pProfile;
        }
        else{
            lValue = pValue - pProfile;
        }
        console.log(lValue);
        const lResponse = CharismaEvaluationTemplateApi.UpdateTemplateProfile(pId, lValue);
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.profile !== null){
                    const lId = value.profile.templateId;
                    const lProfile = value.profile.profile;
                    let lEvaluationTemplatesList = evaluationTemplates.slice();
                    for(let lIndex = 0; lIndex < lEvaluationTemplatesList.length; lIndex++){
                        if(lId === lEvaluationTemplatesList[lIndex].id){
                            lEvaluationTemplatesList[lIndex].profile = lProfile;
                            break;
                        }
                    }
                    setEvaluationTemplates(lEvaluationTemplatesList); 
                }
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        }) 
    }
    
    function handleDelete(){
        const lResponse = CharismaEvaluationTemplateApi.DeleteEvaluationTemplates(selected);
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.ids !== null){
                    let lEvaluationTemplates = evaluationTemplates.filter((evaluationTemplate)=>(value.ids.indexOf(evaluationTemplate.id) === -1));
                    setEvaluationTemplates(lEvaluationTemplates);
                    setSelected([]);
                }
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        }) 
    }
    
    return(
        
        <Box>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={5}>
                    <Card>
                        <CardHeader
                            title={props.title}
                            action={
                                <div>
                                <IconButton onClick={()=>setCreate(true)}>
                                    <AddIcon />
                                </IconButton>
                                <IconButton onClick={()=>setUpload(true)}>
                                    <FileUploadIcon/>
                                </IconButton>
                                {selected.length > 0 ?
                                    <IconButton onClick={()=>setDeleteEvaluationTemplate(true)}>
                                        <DeleteIcon/>
                                    </IconButton>  
                                    :
                                    null
                                } 
                                </div>
                            } 
                        />
                        <CardContent>
                        <TableContainer sx={{ maxHeight: 800 }}>
                            <Table stickyHeader>                               
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                        </TableCell>
                                        <TableCell>
                                            Titre
                                        </TableCell>
                                        {lType === 0 || lType === 3 ?
                                            <TableCell align='center'>
                                            Entreprise
                                            </TableCell>
                                            :
                                            null
                                        }
                                        {lType === 0 || lType === 3 ?
                                            <TableCell align='center'>
                                            Centre de Formation
                                            </TableCell>
                                            :
                                            null
                                        }
                                        <TableCell padding="checkbox">
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                    evaluationTemplates.map((evaluationTemplate)=>(
                                        <TableRow>
                                            <TableCell>
                                                <Checkbox
                                                    checked={selected.indexOf(evaluationTemplate.id) !== -1}
                                                    onClick={(event)=>handleSelected(event, evaluationTemplate.id)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                {evaluationTemplate.title}
                                            </TableCell>
                                            {
                                                lType === 0 || lType === 3 ?
                                                <TableCell align='center'>
                                                    <Checkbox
                                                        checked={evaluationTemplate.profile === 2 || evaluationTemplate.profile === 3}
                                                        onClick={
                                                            (event)=>updateEvaluationTemplateVisibility(evaluationTemplate.id, 2, evaluationTemplate.profile, event)
                                                            }
                                                    />
                                                </TableCell>
                                                :
                                                null
                                            }
                                            {
                                                lType === 0 || lType === 3 ?
                                                <TableCell align='center'>
                                                    <Checkbox
                                                        checked={evaluationTemplate.profile === 1 || evaluationTemplate.profile === 3}
                                                        onClick={
                                                            (event)=>updateEvaluationTemplateVisibility(evaluationTemplate.id, 1, evaluationTemplate.profile, event)
                                                            }
                                                    />
                                                </TableCell>
                                                :
                                                null
                                            }
                                            <TableCell padding="checkbox">
                                                <IconButton
                                                    onClick={()=>handleEdit(evaluationTemplate.id)}
                                                >
                                                    <EditIcon/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>     
                                    ))
                                }
                                </TableBody>
                            </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={7}>
                    {currentId >0 ?
                        <CharismaEvaluationTemplate
                            templateType = {lType}
                            evaluationTemplateId={currentId}
                            handleUpdate={handleUpdate}
                            handleCancel={handleCancelUpdate}
                        />
                    :
                    null
                    }                
                </Grid>
            </Grid>
            {create ?
                <CharismaEvaluationTemplateForm                    
                    title={lType === 0 ? "Ajout d'un examen" : lType === 1 ? "Ajout d'un QCM" : "Ajout d'un devoir"}
                    handleSubmit={handleSubmit}
                    handleCancel={handleCancel}
                />
                :
                null    
            }    
            {upload ?
                <CharismaImportTemplateForm
                    handleSubmit={validUpload}
                    handleCancel={cancelUpload}
                />
                :
                null                
            }
            <Dialog
                open={deleteEvaluationTemplate}
            >
                <DialogTitle>Suppression de rapport</DialogTitle>
                <DialogContent>
                    <div>
                        <Typography>Etes-vous sûr de vouloir supprimer définitivement les rapports sélectionnés?</Typography>
                        <Alert severity='warning'>Cette action sera définitive</Alert>
                    </div>
                    
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setDeleteEvaluationTemplate(false)}>
                        Annuler
                    </Button>
                    <Button onClick={()=>{handleDelete();setDeleteEvaluationTemplate(false)}}>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog> 
                  
        </Box>      
    );
    
    
}