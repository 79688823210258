import * as React from 'react';


import Box from '@mui/material/Box'


import AddIcon from '@mui/icons-material/Add'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Card from'@mui/material/Card'
import CardContent from'@mui/material/CardContent'
import CardHeader from'@mui/material/CardHeader'
import { CharismaExamApi } from '../api/exams/CharismaExamApi.tsx';
import { CharismaUserApi } from '../api/users/CharismaUserApi.tsx';
import { CharismaEvaluationTemplateApi } from '../api/evaluations/CharismaEvaluationTemplateApi.tsx';
import { CharismaStudentTrainingApi } from '../api/studentTrainings/CharismaStudentTrainingApi.tsx';
import CharismaExamForm from './examForm.tsx';
import { CharismaUserContext } from '../context.tsx';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit'
import {formatDate} from '../utils.tsx'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';




export default function CharismaExamsList(props){
    
    interface exam{
        date:string,
        title:string,
        template:string,
        id:number
    }
    const [examinators, setExaminators] = React.useState([]);
    const [students, setStudents] = React.useState([]);
    const [currentExam, setCurrentExam] = React.useState(null);
    const [create, setCreate] = React.useState(false);
    const [examsList, setExamsList] = React.useState([]);
    const [evals, setEvals] = React.useState([]);
    const [deleteExams, setDeleteExams] = React.useState(false);
    const [selectedExams, setSelectedExams] = React.useState([]);    
    const lCurrentUser = React.useContext(CharismaUserContext);
    const lReadWrite = lCurrentUser !== null && lCurrentUser !== undefined && lCurrentUser.profile === 0;
    


    React.useEffect(()=>{
        const lResponseExams = CharismaExamApi.ListExams(props.trainingId);
        lResponseExams.then((value)=>{
            if(value.error === 0){
                setExamsList(value.exams);
            }
        }).catch((error)=>{
            
        })
        
        const lResponseStudents = CharismaStudentTrainingApi.ListStudents(props.trainingId)
        lResponseStudents.then((value)=>{
            if(value.error === 0){
                setStudents(value.studentTrainings);
            }
        }).catch((error)=>{
            
        })
        
        const lResponseExaminators = CharismaUserApi.List(4, 0);
        lResponseExaminators.then((value)=>{
            if(value.error === 0){
                setExaminators(value.users);
            }
        }).catch((error)=>{
            
        })
        const lResponseEvals = CharismaEvaluationTemplateApi.ListEvaluationTemplatesByProfile(0, 1);
        lResponseEvals.then((value)=>{
            if(value.error === 0){
                if(value.evaluationTemplates !== null){
                    setEvals(value.evaluationTemplates);
                }
                
        }
        }).catch((error)=>{
        
        })        

    }, [props.trainingId])
    
    function handleCancel(){
        setCreate(false);
    }
        
    function handleDeleteExams(){
        const lResponse = CharismaExamApi.DeleteExams(selectedExams);
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.ids !== null){
                    const lIds = value.ids;
                    let lNewExams = examsList.filter((lExam)=>lIds.indexOf(lExam.id) === -1);
                    setExamsList(lNewExams);                  
                }
;            }
            else{
            
            }
        }).catch((error)=>{
            console.log("catch error")
        })           
    }    
      
    function handleCreateExam(pTitle : string, pTemplateId: number, pDate: string, pExaminators : number [], pStudents: number[]){
        const lResponse = CharismaExamApi.CreateExam(pTitle, pExaminators, pDate, pTemplateId, props.trainingId, pStudents)
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.exam !== null){
                    let lExams = examsList.concat(value.exam);
                    setExamsList(lExams);
                }                
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })
    }    
    
    function handleUpdateExam(pName : string, pStartDate : string, pEndDate: string){
        const lResponse = CharismaExamApi.UpdateExam(pName, pStartDate, pEndDate, props.trainingId, );
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.course !== null){
                    /*
                    let lCourses = coursesList.concat(value.course);
                    setCoursesList(lCourses);*/
                    setCreate(false);
                }
                
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })
    }    
    
    function handleSelectedExams(pEvent : React.ChangeEvent<HTMLInputElement>, pId : number){
        let lNewSelected : number[] = [];
        let lIndex = selectedExams.indexOf(pId);
        if(pEvent.target.checked && lIndex === -1){
            lNewSelected = lNewSelected.concat(selectedExams, pId);
        }
        else if(lIndex !== -1){
            if(lIndex === 0){
                lNewSelected = lNewSelected.concat(selectedExams.slice(1));
            }
            else if(lIndex === selectedExams.length - 1){
                lNewSelected = lNewSelected.concat(selectedExams.slice(0, -1));
            }
            else{
                lNewSelected = lNewSelected.concat(selectedExams.slice(0, lIndex), selectedExams.slice(lIndex + 1),);
            }
        }
        setSelectedExams(lNewSelected);
    }
    
    
    function handleSubmit(pTitle : string, pTemplateId: number, pDate: string, pExaminators : number [], pStudents: number[]){
        handleCreateExam(pTitle, pTemplateId, pDate, pExaminators, pStudents);

    }
    
    function handleUpdate(pTitle : string, pTemplateId: number, pDate: string, pExaminators : number [], pStudents: number[], pId : number){
        handleUpdateExam(pTitle, pTemplateId, pDate, pExaminators, pStudents, pId);        
    }
    
    function handleCancelUpdate(){
        setCurrentExam(null);
    }
    
    function handleEdit(pId : number){
        const lResponse = CharismaExampApi.GetExam(pId);
        lResponse.then((value)=>{
            if(value.error === 0){
                setCurrentExam(value.exam);
                
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })  
    }
    
    function CustomTabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;
    
        return (
            <div
              role="tabpanel"
              hidden={value !== index}
              id={`simple-tabpanel-${index}`}
              aria-labelledby={`simple-tab-${index}`}
              {...other}
            >
              {value === index && (
                <Box sx={{ p: 3 }}>
                  <Typography component={"div"}>{children}</Typography>
                </Box>
              )}
            </div>
        );
    }
    
    function createExamsTable(pExams : exam[], pSelected : number[]){
        return(
            pExams.map((exam)=>(
                <TableRow key={exam.id}>
                    {lReadWrite ?
                        <TableCell padding="checkbox">
                            <Checkbox
                                checked={pSelected.indexOf(exam.id) >= 0}
                                onClick={                                    
                                    (event)=>handleSelectedExams(event, exam.id)
                                }                                    
                            />
                        </TableCell>
                        :
                        null
                    }
                    <TableCell>
                        {exam.title}
                    </TableCell>
                    <TableCell sx={{display:{xs:'none', md:'table-cell'}}}>
                        {formatDate(exam.date)}
                    </TableCell>
                    <TableCell sx={{display:{xs:'none', md:'table-cell'}}}>
                        {formatDate(exam.template)}
                    </TableCell>
                    {lReadWrite ?
                        <TableCell padding="checkbox">
                            <IconButton
                                onClick={()=>handleEdit(exam.id)}
                            >
                                <EditIcon/>
                            </IconButton>
                        </TableCell>
                        :
                        null
                    }
                </TableRow>
                 
            ))
        )
    }
    
    
    return(
        
        <Box>
            <Card>
                <CardHeader
                    title="Sessions d'examens"
                    action={
                        lReadWrite?
                            <div>
                            <IconButton onClick={()=>setCreate(true)}>
                                <AddIcon/>
                            </IconButton>
                            {selectedExams.length > 0?
                                <IconButton onClick={()=>setDeleteExams(true)}>
                                    <DeleteIcon/>
                                </IconButton>  
                                :
                                null
                            } 
                            </div>
                            :
                            null
                        }
                />
                <CardContent>
                    <Table>                               
                        <TableHead>
                            <TableRow>
                                {lReadWrite ?
                                    <TableCell padding="checkbox">
                                        </TableCell>
                                      :
                                      null
                                }
                                <TableCell>Nom</TableCell>
                                <TableCell sx={{display:{xs:'none', md:'table-cell'}}}>Date</TableCell>
                                <TableCell sx={{display:{xs:'none', md:'table-cell'}}}>Examen</TableCell>
                                {lReadWrite ?
                                    <TableCell padding="checkbox">
                                    </TableCell>
                                      :
                                      null
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                            createExamsTable(examsList, selectedExams)
                        }
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
            {create ?
                <CharismaExamForm
                    title={"Ajout d'une session d'examen"}
                    students={students}
                    examinators={examinators}
                    handleSubmit={handleCreateExam}
                    handleCancel={handleCancel}
                    minDate={props.startDate}
                    maxDate={props.endDate}
                    evals={evals}
                />
                :
                null    
            }    
            {
                currentExam !== null ?
                <CharismaExamForm
                    title={currentExam.title}
                    date={currentExam.date}
                    students={students}
                    examinators={examinators}
                    templateId={currentExam.templateId}
                    id={currentExam.id}
                    minDate={props.startDate}
                    maxDate={props.endDate}
                    handleSubmit={handleUpdateExam}
                    handleCancel={handleCancelUpdate}
                    evals={evals}
                />
                :
                null
            }
            <Dialog
                open={deleteExams}
            >
                <DialogTitle>Suppression de Session</DialogTitle>
                <DialogContent>
                    <Typography>Etes-vous sûr de vouloir supprimer les sessions sélectionnés?</Typography>
                    {
                        false?
                            <Alert severity='info'>Suppression en cours...</Alert>
                        :
                        null
                    }
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setDeleteExams(false)}>
                        Annuler
                    </Button>
                    <Button onClick={handleDeleteExams}>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>                        
        </Box>      
    );
    
    
}